<template>
  <div id="MainHeader" class="col-12">
    <router-link to="/"
      ><img id="TheLogo" src="@/assets/logo.png"
    /></router-link>
    <div id="NavIcons">
      <div style="position: relative">
        <font-awesome-icon
          id="CartIcon"
          class="icon"
          icon="fa-solid fa-cart-shopping"
          @click="
            this.$store.state.Indexes['Side_Cart'] = 1;
            this.$store.state.Indexes['Side_Filter_Index'] = 0;
          "
        />
        <p id="CartCounter">
          {{ this.$store.state.Data.SideCartSelections.length }}
        </p>
      </div>
      <font-awesome-icon
        v-if="this.$store.state.Sizes['Window_Width'] < 992"
        class="icon"
        icon="fa-solid fa-bars"
        style="color: white"
        @click="
          this.$store.state.Indexes['Side_Filter_Index'] == 1
            ? (this.$store.state.Indexes['Side_Filter_Index'] = 0)
            : (this.$store.state.Indexes['Side_Filter_Index'] = 1)
        "
      />
      <font-awesome-icon
        class="icon"
        style="color: white"
        icon="fa-solid fa-arrows-rotate"
        @click="this.syncWithZoho()"
      />
      <font-awesome-icon
        class="icon"
        style="color: white"
        icon="fa-solid fa-print"
        v-if="this.$route.name == 'summary'"
        @click="this.print()"
      />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
export default {
  name: "MainHeader",
  components: {},
  data() {
    return {};
  },
  created() {},
  mounted() {},
  computed: {
    totalCart() {
      return 20;
    },
  },
  methods: {
    print(){
      window.print();
    },
    syncWithZoho() {
      localStorage.clear();
      window.location.reload();
    },
  },
};
</script>
<style lang="scss" scoped>
#MainHeader {
  display: flex;
  height: 5rem;
  padding: 0 5%;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid grey;
  background: #1f242e;
  @media print {
    display: none;
  }

  #TheLogo {
    height: 4rem;
  }

  #NavIcons {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;

    .icon {
      height: 1.5rem;
      width: 1.5rem;
      font-size: 1.5rem;
      margin: 0 1rem;
    }

    #CartIcon {
      padding: 1rem;
      background: #dedede;
      border-radius: 50%;
      cursor: pointer;
    }

    #CartCounter {
      position: absolute;
      right: 7px;
      top: 0;
      height: 0.6rem;
      width: 0.6rem;
      background: #f57e60;
      text-align: center;
      padding: 0.7rem;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-content: center;
      justify-content: center;
      align-items: center;
      font-size: 0.7rem;
      border-radius: 50%;
      color: white;
    }
  }
}
</style>
