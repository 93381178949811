import { createStore } from "vuex";
const Server_Url = "";
// const Server_Url = "http://localhost/puki/public/";
export default createStore({
  state: {
    Api_Url: Server_Url + "php/index.php",
    Server_Link: Server_Url + "php/",
    Sizes: {
      Window_Width: window.innerWidth,
      Window_Height: window.innerHeight,
      Main_Header_Height: 0,
    },
    Indexes: {
      Loader_Index: 0,
      MenuPhone: 0,
      Side_Filter_Index: 1,
      Side_Cart: 0,
      Product_Details: 0,
    },
    Data: {
      FinalCart: [],
      SideCartSelections: [],
      Product_To_Show: null,
    },
    Actions: {
      Working_Tab: "Home",
    },
  },
  getters: {},
  mutations: {},
  actions: {},
  modules: {},
});
