<template>
  <div
    class="col-12"
    id="SideCartContainer"
    v-if="this.$store.state.Indexes['Side_Cart'] == 1"
    @click="this.$store.state.Indexes['Side_Cart'] = 0"
  >
    <div
      id="SideCart"
      class="wow animate__animated animate__fadeInRight"
      @click="$event.stopPropagation()"
    >
      <font-awesome-icon
        class="closeIcon"
        icon="fa-solid fa-circle-xmark"
        @click="this.$store.state.Indexes['Side_Cart'] = 0"
      />
      <h1 class="col-12 CartHeader">Your Cart</h1>
      <ul class="col-12" v-if="this.$store.state.Data['SideCartSelections'].length > 0">
        <li
          class="col-12"
          v-for="(CartItem, index) in this.$store.state.Data['SideCartSelections']"
          :key="CartItem"
        >
          <img :src="this.$store.state.Server_Link + CartItem['product_imgs'][0]" />
          <div class="product_info">
            <p class="col-12">{{ CartItem["product_name"] }}</p>
            <span class="col-12">{{ CartItem["product_price"] }} EGP</span>
            <font-awesome-icon
              class="Icon"
              icon="fa-solid fa-circle-xmark"
              @click="this.$store.state.Data['SideCartSelections'].splice(index, 1)"
            />
          </div>
        </li>
      </ul>
      <p v-else class="col-12">No Items Added To Cards</p>
      <p class="col-12" style="padding : 1rem 0 !important;">Total Cart : {{ this.totalCart }} EGP</p>
      <button class="col-12 btn btn-info" @click="this.$router.push({ name: 'cart' })">
        VIEW CART
      </button>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
export default {
  name: "SideCart",
  components: {},
  data() {
    return {};
  },
  created() {},
  mounted() {},
  computed: {
    totalCart() {
      let Items = this.$store.state.Data["SideCartSelections"];
      let final = 0;
      if (Items.length > 0) {
        Items.forEach((item) => {
          final += +item["product_price"];
        });
      }
      return final;
    },
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
#SideCartContainer {
  height: 100vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  position: fixed;
  z-index: 30;
}
#SideCart {
  height: 100vh;
  width: 20rem;
  position: fixed;
  top: 0;
  right: 0;
  background-color: white;
  color: black;
  display: flex;
  z-index: 20;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-start;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 2rem 1rem;
  .closeIcon {
    position: absolute;
    top: 1.9rem;
    right: 2rem;
    height: 1.4rem;
    width: 1.4rem;
    cursor: pointer;
  }
  .CartHeader {
    font-size: 1.4rem;
    padding-bottom: 1rem;
  }
  ul {
    padding: 0;
    list-style: none;
    max-height: 75vh;
    overflow-y: auto;
    border: solid 1px rgba(128, 128, 128, 0.4);
    li {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: flex-start;
      align-items: center;
      border-top: 1px solid rgba(128, 128, 128, 0.3);
      padding: 1rem 0;
      padding-left: 1rem;
      img {
        height: 5rem;
        width: 5rem;
        object-fit: contain;
      }
      .product_info {
        flex-grow: 1;
        position: relative;
        p {
          font-size: 1rem;
          padding-left: 1rem;
        }
        span {
          font-size: 0.8rem;
          padding-left: 1rem;
        }
        .Icon {
          position: absolute;
          top: 1rem;
          right: 1rem;
          cursor: pointer;
        }
      }
    }
  }
}
</style>
