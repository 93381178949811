<template>
  <MainHeader />
  <MainLoader />
  <router-view />
  <SideCart />
</template>

<script>
// oncontextmenu="return false;"
// Using of WoW
// import WOW from "wowjs";
// mounted() {
// new WOW.WOW({
//   live: false,
// }).init();
//   },
import MainLoader from "@/components/MainLoader.vue";
import MainHeader from "@/components/MainHeader.vue";
import SideCart from "@/components/SideCart.vue";
import $ from "jquery";
window.$ = window.jQuery = require("jquery");

export default {
  name: "AppView",
  components: { MainLoader, MainHeader, SideCart },
  data() {
    return {};
  },
  created() {
    let main = this;
    $(window).resize(function () {
      main.$store.state.Sizes["Window_Width"] = window.innerWidth;
      main.$store.state.Sizes["Window_Height"] = window.innerHeight;
    });
  },
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Archivo:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Gantari:wght@100;200;300;400;500;600;800;900&display=swap");

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  // font-family: "Gantari", sans-serif !important;
  font-family: 'Archivo', sans-serif;
}

html {
  scroll-behavior: smooth;
}

p,
h1,
h2,
h3,
span,
ul {
  margin: 0 !important;
}

body {
  min-height: 100vh;
  width: 100%;
  overflow: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-start;
  justify-content: flex-start;
  align-items: flex-start;
  #app {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: flex-start;
    justify-content: flex-start;
    align-items: flex-start;
    background-color: #f2f2f2;
    // @media screen and (max-width: 767px) {
    //   padding: 0 1rem;
    // }
  }
}

@media screen and (min-width: 100px) {
  /* font at xs */
  html {
    font-size: 12px !important;
  }
}

@media screen and (min-width: 768px) {
  /* font at sm */
  html {
    font-size: 13px !important;
  }
}

@media screen and (min-width: 991px) {
  /* font at md */
  html {
    font-size: 14px !important;
  }
}

@media screen and (min-width: 1200px) {
  /* font at lg */
  html {
    font-size: 15px !important;
  }
}
</style>
