import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Pagination from "v-pagination-3";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap";
import "animate.css";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import VueTelInput from "vue-tel-input";
// import "vue-tel-input/dist/vue-tel-input.css";
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import {
  faBell,
  faImage,
  faTrashCan,
} from "@fortawesome/free-regular-svg-icons";
import {
  faFacebook,
  faInstagram,
  faOpencart,
  faTelegram,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import {
  faAddressCard,
  faArrowsRotate,
  faBars,
  faBarsStaggered,
  faBatteryFull,
  faBatteryHalf,
  faBatteryThreeQuarters,
  faCaretDown,
  faCaretRight,
  faCartShopping,
  faCircleChevronLeft,
  faCircleXmark,
  faClipboardList,
  faDiamond,
  faEllipsis,
  faExpand,
  faEye,
  faEyeDropper,
  faGaugeHigh,
  faGrip,
  faHeart,
  faHouse,
  faLayerGroup,
  faMagnifyingGlass,
  faPen,
  faPeopleGroup,
  faPlus,
  faPowerOff,
  faPrint,
  faRightToBracket,
  faStarHalfStroke,
  faTruckMedical,
  faWarehouse,
  faX,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
library.add(
  faPrint,
  faArrowsRotate,
  faGrip,
  faPowerOff,
  faGaugeHigh,
  faMagnifyingGlass,
  faClipboardList,
  faPeopleGroup,
  faBell,
  faExpand,
  faWarehouse,
  faStarHalfStroke,
  faCircleXmark,
  faPlus,
  faPen,
  faTrashCan,
  faEllipsis,
  faX,
  faImage,
  faCircleChevronLeft,
  faBarsStaggered,
  faHouse,
  faAddressCard,
  faRightToBracket,
  faTruckMedical,
  faBatteryFull,
  faBatteryThreeQuarters,
  faBatteryHalf,
  faDiamond,
  faFacebook,
  faInstagram,
  faTelegram,
  faYoutube,
  faHeart,
  faLayerGroup,
  faOpencart,
  faBars,
  faEyeDropper,
  faXmark,
  faEye,
  faCaretRight,
  faCaretDown,
  faCircleXmark,
  faCartShopping
);

createApp(App)
  .use(store)
  .use(router)
  .use(VueSweetalert2)
  .use(VueTelInput)
  .component("font-awesome-icon", FontAwesomeIcon)
  .component("pagination", Pagination)
  .mount("#app");

// npm install axios
// npm install bootstrap
// npm install jquery
// npm install @vuelidate/core @vuelidate/validators
// npm install @fortawesome/fontawesome-svg-core
// npm install @fortawesome/free-solid-svg-icons
// npm install @fortawesome/free-regular-svg-icons
// npm install @fortawesome/free-brands-svg-icons
// npm install @fortawesome/vue-fontawesome@latest-3
// npm install animate.css
// npm install -S vue-sweetalert2
// npm install wowjs
// npm install vue3-carousel
// npm install v-pagination-3
// npm install vue-tel-input
